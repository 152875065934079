import styled from '@emotion/styled';
import { ReactComponent as HeroImage } from './hero.svg';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const Hero = styled(HeroImage)`
  margin-top: auto;
`;

export const Title = styled.div`
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  padding: 30px 0;

  strong {
    display: block;
  }
`;

export const SubTitle = styled.div`
  color: #b3bfc9;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  text-align: center;
  padding-bottom: 100px;
`;

export const BrandRegion = styled(Link)`
  margin-top: auto;
  padding-bottom: 30px;
  display: block;

  img {
    height: 20px;
  }
`;
