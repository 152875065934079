import React from 'react';
import { Helmet } from 'react-helmet';
import Splash from './components/splash';

export default () => (
  <>
    <Helmet>
      <title>404 Not found</title>
      <meta name="description" content="404 Not found" />
    </Helmet>
    <Splash />
  </>
);
