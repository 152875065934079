import React from 'react';
import { Container, Hero, Title, SubTitle, BrandRegion } from './style';
import { Brand } from '../../../../components/logo';

export default () => (
  <Container>
    <Hero />
    <Title>
      To infinity and... ooops... <strong>page not found.</strong>
    </Title>
    <SubTitle>404 error</SubTitle>
    <BrandRegion to="/">
      <Brand />
    </BrandRegion>
  </Container>
);
